function Stats() {
  let prevTime = Date.now();
  let frames = 0;
  let fps = 0;

  return {
    getFPS: function () {
      frames++;

      let time = Date.now();

      if (time >= prevTime + 1000) {
        fps = Math.round((frames * 1000) / (time - prevTime));
        prevTime = time;
        frames = 0;
      }

      return fps;
    },
  };
}

export default Stats;
