import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Kbd } from "@chakra-ui/react";

const PracticeDir2 = (props) => {
  let f, j;
  if (props.keybind < 0.5) {
    f = props.choices[0];
    j = props.choices[1];
  } else {
    j = props.choices[0];
    f = props.choices[1];
  }

  const transition1 = useCallback(
    (e) => {
      props.hideself(false);
      props.transition();
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener("keydown", transition1, false);

    return () => {
      document.removeEventListener("keydown", transition1, false); // to prevent mem leaks
    };
  }, [transition1]);

  return (
    <>
      <form>
        <h1 className="intro-header-text">Practice Session</h1>
        <label>You have completed the first block!</label>
        <br></br>
        <label>
          For the next block, images will be shown <strong>faster.</strong>
        </label>
        <br></br>
        <label>Place your left hand index finger on the 'f' key.</label>
        <br></br>
        <label>Place your right hand index finger on the 'j' key.</label>
        <br></br>
        <br />
        <label>
          <strong>Press any key to continue</strong>
        </label>
        <br />
        <br />
      </form>
    </>
  );
};

export default PracticeDir2;
