import { HStack, Heading, Text } from "@chakra-ui/react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: window.screen.width > 768 ? 110 : 70,
  strokeWidth: window.screen.width > 768 ? 6 : 4,
  trailColor: "#1a202c",
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <Heading size={{ md: "sm", lg: "xl" }}>{time}</Heading>
      <Text fontSize="xs">{dimension}</Text>
    </div>
  );
};

const getNextWednesday = () => {
  const today = new Date();
  
  // Sunday = 0, Monday = 1, ..., Saturday = 6
  const dayOfWeek = today.getUTCDay(); // Get the day of the week in UTC
  const currentHour = today.getUTCHours();

  let daysUntilWednesday = (4 - dayOfWeek + 7) % 7; // Calculate days until next Wednesday

  if (dayOfWeek === 4 && currentHour >= 1) {
    // 1 AM UTC Thursday is 9 PM EDT Wednesday (UTC-5 or UTC-4 depending on DST)
    daysUntilWednesday = 7;
  }

  const nextWednesday = new Date(today);
  nextWednesday.setUTCDate(today.getUTCDate() + daysUntilWednesday);
  nextWednesday.setUTCHours(1, 0, 0, 0); // Set time to 1 AM UTC (which is 9 PM EDT)
  return nextWednesday.getTime();
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

const CountdownTimer = () => {
  const startTime = Date.now() / 1000;
  const endTime = getNextWednesday() / 1000;

  const remainingTime = endTime - startTime;
  const days = Math.floor(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  return (
    <HStack
      spacing="24px"
      align="center"
      justify="center"
      style={{ width: "100%", flexWrap: "wrap" }}
      mb={{ lg: "6vh" }}
    >
      <CountdownCircleTimer
        {...timerProps}
        colors="#30ACFB"
        duration={daysDuration}
        initialRemainingTime={remainingTime}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#556AE3"
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > hourSeconds,
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#6749D7"
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds,
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#7928CA"
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > 0,
        })}
      >
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("seconds", getTimeSeconds(elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
    </HStack>
  );
};

export default CountdownTimer;
