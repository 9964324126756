import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Link,
} from "@chakra-ui/react";

const ConsentModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="3xl"
      scrollBehavior={"inside"}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent bgColor="#1a202c">
        <ModalHeader>
          <Text color="#30ACFB" fontSize="3xl" as="b">
            BROWN UNIVERSITY
          </Text>
          <Text fontSize="xl">CONSENT FOR RESEARCH PARTICIPATION</Text>
          <Text>Human-Level Neuromorphic Artificial Visual System</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight="bold" marginBottom="0" fontSize="xl">
            Overview
          </Text>
          <Text fontSize="md">
            You are invited to take part in a Brown University research study. Your participation is voluntary.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Researcher
          </Text>
          <Text fontSize="md">
            Thomas R. Serre, PhD
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Purpose
          </Text>
          <Text fontSize="md">
            The study is about measuring human performance in visual recognition tasks. 
            You are being asked to be in this study because we seek to gain a basic understanding 
            of how the visual system rapidly interprets and organizes visual scenes.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Procedures
          </Text>
          <Text fontSize="md">
            You will be asked to view images containing objects and click on parts of these 
            objects that you think are important for recognizing them. You will be asked 
            questions evaluating your visual perception of the image. We have anti-cheat 
            software in place to flag fraudulent activity and ensure those on the leaderboard 
            are playing the game with integrity. Your performance may be compared to other 
            players, and this could impact your score. Your account will be suspended if 
            cheating is detected, and you will be given the chance to appeal via email. 
            We reserve the right to deactivate accounts we deem to be fraudulent.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Time Involved
          </Text>
          <Text fontSize="md">
            There is no minimal time to play the game. You can stop playing whenever you would like. 
            You can also continue playing for as long as you would like.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Compensation
          </Text>
          <Text fontSize="md">
            You will not receive compensation for your time. By participating you 
            will have the opportunity to win a gift card. Five gift cards will be 
            drawn each week, and you can win one of these if you achieve a top-five 
            score for the week. You can compete for a new gift card every week.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Risks
          </Text>
          <Text fontSize="md">
            There are no anticipated risks for the participants in the current study.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Benefits
          </Text>
          <Text fontSize="md">
            You may not directly benefit from being in this research study. 
            However, data from this study may improve our knowledge of how the 
            brain processes visual scenes. The researchers intend to use data from 
            this study for research projects that may appear in scientific conferences 
            and journals.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Confidentiality
          </Text>
          <Text fontSize="md">
            Your participation in this study is confidential. By signing this document, 
            you permit us to use anonymized information for scientific purposes including teaching 
            and/or publication. You may opt-in to provide your email so that we can contact 
            you if you win a prize, and this data will be stored securely on Google Cloud Storage. 
            We will never publish identifiable data collected from this study. You always have 
            the option to delete your personally identifiable email from your profile on our 
            website. Brown University staff sometimes review studies like this one to make sure 
            they are being done safely and correctly. If a review of this study takes place, your 
            records may be examined. The reviewers will protect your confidentiality. Anonymized 
            data may or may not be used and/or shared for future research.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Voluntary
          </Text>
          <Text fontSize="md">
            You do not have to be in this study if you do not want to be. Even if you decide 
            to be in this study, you can change your mind and stop at any time.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Contact Information
          </Text>
          <Text fontSize="md">
            If you have any questions about your participation in this study, you can email{" "} 
            <Link href="mailto:serre-psychophysics@brown.edu" isExternal color="#30ACFB">
              serre-psychophysics@brown.edu
            </Link>
            {" "}.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Your Rights
          </Text>
          <Text fontSize="md">
            If you have questions about your rights as a research participant, you can 
            contact Brown University's Human Research Protection Program at 401-863-3050 
            or email them at{" "}
            <Link href="mailto:IRB@Brown.edu" isExternal color="#30ACFB">
              IRB@Brown.edu
            </Link>
            {" "}.
          </Text>
          <br />
          <Text fontWeight="bold" fontSize="xl">
            Consent to Participate
          </Text>
          <Text fontSize="md">
            Clicking 'I agree' below indicates that you have read and understood the 
            information in this document, are above the age of 18, and that you agree to 
            volunteer as a research participant for this study. It indicates you will make 
            good faith efforts to stay focused on the task, and will not attempt to illegitimately 
            boost your score with external tools or software. This does not waive your legal 
            rights. You can print a copy of the consent form or save this browser window for 
            your own record.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={props.onAgree}>
            I agree
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConsentModal;
