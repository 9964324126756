import React from "react";
import { Text, Box, SlideFade } from "@chakra-ui/react";
import { Fade } from "react-awesome-reveal";
import "remixicon/fonts/remixicon.css";

const Safari = () => {
  return (
    <>
      <Box
        display={"flex"}
        shadow="sm"
        position="fixed"
        w="100vw"
        bg="white"
        zIndex={2}
        border="1px"
        borderColor="gray.200"
      >
        <Box p={3} fontSize="4xl" textColor="#30ACFB">
          RVIS
        </Box>
      </Box>

      <Box display={"flex"}>
        <Box pt="10%" m="auto" fontSize={"9xl"} textColor="#30ACFB">
          <i className="ri-error-warning-line" />
        </Box>
      </Box>

      <Box m="auto" p="5%" textAlign={"center"}>
        <Text fontSize={"5xl"} fontWeight={"bold"} pb="4%">
          Safari is <strong>not</strong> supported.
        </Text>
        <Text pb="5%" fontSize={"xl"}>
          In order to participate in rapid visual categorization research,
          please use another browser such as Chrome, FireFox, or Edge.
        </Text>
      </Box>

      <SlideFade id="about-text" in={true} delay={0.4} pt={"10%"}>
        <Fade>
          <Text id="about-h2" fontSize="3xl" textAlign={"left"}>
            ABOUT <i className="ri-information-line" />
          </Text>
          <Box id="about-p" fontSize="2xl" textAlign={"left"}>
            Rapid visual categorization involves seeing an image for a fraction
            of a second, and immediately determining what was in it. In this
            game, your goal is to determine which images have animals in them!
            <br></br>
            <br></br>
            By playing rapid visual categorization, you're helping us improve
            artificial intelligence (AI) systems, which try to mimic natural
            vision using computational techniques.
            <br></br>
            <br></br>
            Every time you play the game, you're providing researchers with
            information about the complexities of the human visual system.
            You're helping us understand how and why humans are able to process
            what is seen so quickly!
            <br></br>
            <br></br>
            Every month, we give cash prizes to the top players on the
            leaderboard.
            <br></br>
            <br></br>
            <strong>PLAY NOW TO WIN CASH PRIZES!</strong>
          </Box>
        </Fade>
        <Fade>
          <Text id="about-h2" fontSize="3xl" textAlign={"left"}>
            MOTIVATION <i className="ri-lightbulb-line" />
          </Text>
          <Box id="about-p" fontSize="2xl" textAlign={"left"}>
            Our visual system is a remarkable mystery of nature. Regardless of
            whether an object is blurry, upside down, or transformed in a way we
            have never seen before, we can recognize it nearly instantly. Even
            the latest and greatest AI systems like OpenAI's GPT-4 (ChatGPT's
            successor that can process both vision and language) are no match
            for human vision, across a variety of diverse tasks and metrics.
            <br />
            <br />
            We are investigating the contributions of feedforward and feedback
            mechanisms in visual perception using a well-established paradigm
            called rapid visual categorization. Decades of neuroscience,
            psychology, and neurophysiology research has shown that the human
            brain can process visual stimuli and detect objects or animals in as
            little as 20 milliseconds. By showing images for very short
            presentation times and eliciting quick behavioral responses, we are
            able to uncover the inner workings of the human visual system.
          </Box>
        </Fade>
        <Fade>
          <Text id="about-h2" fontSize="3xl" textAlign={"left"}>
            HOW WILL MY DATA BE USED? <i className="ri-pie-chart-2-line" />
          </Text>
          <Box id="about-p" fontSize="2xl" textAlign={"left"}>
            We are using the data from this experiment to train a set of AI
            models that are more aligned with biology than anything the field
            has seen. Beyond creating newer and more computationally efficient
            AI, this data can be used to robustly assess the alignment between
            existing computer vision models and humans.
            <br></br>
            <br></br>
            You are in control of your personal information. You always have the
            right to delete your account, permanently removing your email and
            personally identifiable data from all of our databases.
          </Box>
        </Fade>
      </SlideFade>
    </>
  );
};

export default Safari;
