import React, { useEffect, useCallback, useState } from "react";
import { Box, Kbd } from "@chakra-ui/react";

const MainDir = (props) => {
  let f, j;
  if (props.keybind < 0.5) {
    f = props.choices[0];
    j = props.choices[1];
  } else {
    j = props.choices[0];
    f = props.choices[1];
  }

  const [done, setDone] = useState(false);

  const transition1 = useCallback(
    (e) => {
      if (done) {
        props.hideself(false);
        props.transition();
      } else {
        setDone(true);
      }
    },
    [done, props]
  );

  useEffect(() => {
    document.addEventListener("keydown", transition1, false);

    return () => {
      document.removeEventListener("keydown", transition1, false); // to prevent mem leaks
    };
  }, [transition1]);

  return (
    <>
      {done && (
        <form>
          <h1 className="intro-header-text">Main Session</h1>
          <label>
            Press <strong>f</strong> on your keyboard for {f} or{" "}
            <strong>j</strong> for {j}.
          </label>
          <br></br>
          <div>
            <label>{f}</label>
            <Kbd mx="4" fontSize="3xl" color="black">
              f
            </Kbd>
            <Kbd mx="4" fontSize="3xl" color="black">
              j
            </Kbd>
            <label>{j}</label>
          </div>
          <label>
            You will <strong>not</strong> receive feedback on your answer.
          </label>
          <br></br>
          <label>
            If you fail to respond within one second your answer will count as{" "}
            <span id="wrong-small">wrong</span>.
          </label>
          <br></br>
          <label>
            You’ll receive points for every correct answer <br />
            and get the total number of points at the end of every block.
          </label>
          <br></br>
          <label>
            The <strong>faster</strong> you respond, the{" "}
            <strong>more points</strong> you'll receive!
          </label>
          <br></br>
          <label>Each block is 50 images.</label>
          <br />
          <br />
          {/* <input
          type="submit"
          onClick={() => {
            transition1();
          }}
          value="BEGIN"
          className="accept-btn"
        /> */}
          <label>
            <strong>Press any key to continue</strong>
          </label>
          <br />
          <br />
        </form>
      )}
      {!done && (
        <form>
          <h1 className="intro-header-text">Main Session</h1>
          <label>You will now begin the main session.</label>
          <br></br>
          <label>Place your left hand index finger on the 'f' key.</label>
          <br></br>
          <label>Place your right hand index finger on the 'j' key.</label>
          <br></br>
          <label>
            You will be shown images much faster than in the practice session.{" "}
          </label>
          <br />
          <label>
            Your task is to identify if the image contains an animal or
            non-animal.
          </label>
          <br></br>
          <br />
          <label>
            <strong>Press any key to continue</strong>
          </label>
          <br />
          <br />
        </form>
      )}
    </>
  );
};

export default MainDir;
