import React from "react";
import "./module.css";
import { SlideFade, Box } from "@chakra-ui/react";

const Module = (props) => {
  return (
    <SlideFade in={true} delay={0.2}>
      <div
        className={`${props.shown ? "" : "active"} show`}
        overflow-y="auto"
        overflow-x="auto"
      >
        <div className="module-container">
          <div className="form-box solid">
            <>{props.children}</>
          </div>
        </div>
      </div>
    </SlideFade>
  );
};

export default Module;
