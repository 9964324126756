import React, { useEffect } from "react";
import { Button, Box, Kbd } from "@chakra-ui/react";

const Reminder = (props) => {
  let f, j;
  if (props.keybind < 0.5) {
    f = props.choices[0];
    j = props.choices[1];
  } else {
    j = props.choices[0];
    f = props.choices[1];
  }

  const handleQuit = () => {
    window.location.reload();
  };

  return (
    <Box
      className={`${props.shown ? "" : "active"} show`}
      overflow-x="auto"
      display="flex"
    >
      <Box
        display="flex"
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        ml="auto"
      >
        {/* {f}
        <Kbd mx="2" fontSize="xl">
          f
        </Kbd>
        <Kbd mx="2" fontSize="xl">
          j
        </Kbd>
        {j} */}
      </Box>
      <Button
        color={"white"}
        variant="outline"
        ml="auto"
        mt={2}
        mr={2}
        size="sm"
        onClick={() => handleQuit()}
      >
        Quit
      </Button>
    </Box>
  );
};

export default Reminder;
