import React, { useEffect, useState } from "react";
import {
  HStack,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Text,
} from "@chakra-ui/react";
import "remixicon/fonts/remixicon.css";

const NavBar = (props) => {
  const [user, setUser] = useState(window.sessionStorage.getItem("user"));
  const handleAboutClick = async (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeSignIn(false);
    props.seeSignUp(false);
    props.seeLeaderboard(false);
    window.sessionStorage.getItem("user")
      ? await props.seeReturning(true)
      : await props.seeHome(true);
    document
      .getElementById("about-text")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleHomeClick = async (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeSignIn(false);
    props.seeSignUp(false);
    props.seeLeaderboard(false);
    props.seeMobile(true);
    window.sessionStorage.getItem("user")
      ? await props.seeReturning(true)
      : await props.seeHome(true);
    document.body.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const seeSignUp = (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeReturning(false);
    props.seeHome(false);
    props.seeSignIn(false);
    props.seeLeaderboard(false);
    props.seeSignUp(true);
  };

  const seeSignIn = (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeReturning(false);
    props.seeHome(false);
    props.seeSignIn(false);
    props.seeLeaderboard(false);
    props.seeSignUp(false);
    props.seeSignIn(true);
  };

  const seeLeaderboard = (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeReturning(false);
    props.seeHome(false);
    props.seeSignIn(false);
    props.seeSignUp(false);
    props.seeMobile(false);
    props.seeLeaderboard(true);
  };

  const seeProfile = (e) => {
    e.preventDefault();
    props.seeReturning(false);
    props.seeHome(false);
    props.seeSignIn(false);
    props.seeSignUp(false);
    props.seeLeaderboard(false);
    props.seeProfile(true);
  };

  const signOut = () => {
    window.sessionStorage.removeItem("user");
    window.sessionStorage.removeItem("keybind");
    window.location.reload();
  };

  useEffect(() => {
    setUser(window.sessionStorage.getItem("user"));
  }, [props.reload, props.user]);
  return (
    <>
      <Box
        display={"flex"}
        shadow="sm"
        // position="fixed"
        w="100vw"
        // bgGradient="linear(to-tr, black, gray.800)"
        zIndex={2}
        // border="1px"
        // borderColor="gray.200"
      >
        <Box
          p={3}
          fontSize="4xl"
          fontWeight={"bold"}
          bgGradient="linear(to-l, #9146db, #30ACFB)"
          bgClip="text"
          onClick={handleHomeClick}
          _hover={{ color: "#93bdf5", cursor: "pointer" }}
        >
          RVIS
        </Box>
        <HStack spacing="10" p="3" mr="auto">
          {/* <Button
            id="content-desktop"
            onClick={handleAboutClick}
            variant="unstyled"
            fontSize="xl"
            fontWeight="normal"
            _hover={{ transform: "scale(1.08)" }}
          >
            Play
          </Button> */}
          <Button
            variant="unstyled"
            fontSize="xl"
            fontWeight="normal"
            onClick={seeLeaderboard}
            _hover={{ transform: "scale(1.08)" }}
          >
            Leaderboard
          </Button>
          <Button
            id="content-desktop"
            onClick={handleAboutClick}
            variant="unstyled"
            fontSize="xl"
            fontWeight="normal"
            _hover={{ transform: "scale(1.08)" }}
          >
            About
          </Button>

          <Button
            id="content-desktop"
            as="a"
            href="https://forms.gle/NXNjKkTSuNjwjUva9"
            target="_blank"
            rel="noopener noreferrer"
            fontSize="xl"
            fontWeight="normal"
            variant="unstyled"
            pt={1}
            _hover={{ transform: "scale(1.08)" }}
          >
            Contact Us
          </Button>
        </HStack>
        {window.sessionStorage.getItem("user") ? (
          <HStack spacing="10" px="5" id="content-desktop">
            <Menu>
              <MenuButton display="flex" mx={5} ml="auto">
                <Avatar name={user} cursor="pointer" />
              </MenuButton>
              <MenuList bgColor="#1a202c">
                <MenuItem onClick={seeProfile} bgColor="#1a202c">
                  <i className="ri-user-line" />
                  <Text ml={3}>View Profile</Text>
                </MenuItem>
                <MenuItem onClick={signOut} bgColor="#1a202c">
                  <i className="ri-logout-box-line" />
                  <Text ml={3}>Sign Out</Text>
                </MenuItem>
              </MenuList>
            </Menu>
            {/* <Box fontSize="3xl">Sign In</Box> */}
          </HStack>
        ) : (
          <HStack spacing="10" p="5" id="content-desktop">
            <Button
              variant="unstyled"
              fontSize="lg"
              fontWeight="normal"
              onClick={seeSignIn}
              _hover={{ transform: "scale(1.08)" }}
              // color='#93bdf5'
            >
              Sign In
            </Button>
            <Button
              variant="outline"
              fontSize="lg"
              fontWeight="normal"
              onClick={seeSignUp}
              border="2px"
              // color='#93bdf5'
              color="white"
              _hover={{ transform: "scale(1.08)" }}
            >
              Sign Up
            </Button>
          </HStack>
        )}
      </Box>
    </>
  );
};

export default NavBar;
