import { React, useEffect, useState } from "react";
import {
  SlideFade,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  Box,
  Highlight,
} from "@chakra-ui/react";
import { Fade } from "react-awesome-reveal";
import "remixicon/fonts/remixicon.css";
import { ArrowForwardIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import CountdownTimer from "./countdowntimer";

const Home = (props) => {
  const transition1 = () => {
    props.hideself(false);
    props.transition(true);
  };

  const seeLeaderboard = () => {
    props.hideself(false);
    props.leaderboard(true);
  };

  const [scrollBehavior] = useState("inside");
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: !window.sessionStorage.getItem("consent"),
  });

  const handleAgree = (e) => {
    e.preventDefault();
    window.sessionStorage.setItem("consent", "agreed");
    // console.log(window.sessionStorage.getItem("consent"));
    props.setConsent(true);
    onClose();
  };

  return (
    <>
      <Box
        id="about"
        className={`${props.shown ? "" : "active"} show`}
        // bgGradient="linear(to-b, blue.100, #ffffff)"
      >
        <SlideFade in={true} delay={0.2}>
          {/* <Box color="#092232">
            <Box id="about-h1" fontSize="7xl">
              Welcome to
            </Box>
            <Box id="rvc" fontSize="7xl">
              Rapid Visual Categorization!
            </Box>
          </Box> */}
          <Box id="about-h1">
            {/* <Text fontSize={'3xl'}>Welcome to </Text> */}
            <Text
              fontSize={"6xl"}
              fontWeight={"bold"}
              bgGradient="linear(to-l, #9146db, #30ACFB)"
              bgClip="text"
              textAlign={"center"}
            >
              Rapid Visual Categorization
            </Text>
          </Box>

          <Box display={"flex"}>
            <Button
              // className="signup-btn"
              ml="auto"
              mr="auto"
              mt="4"
              // mb="4"
              color="white"
              variant={"outline"}
              height="6vh"
              fontSize="2xl"
              rightIcon={<ArrowForwardIcon />}
              _hover={{
                bgGradient: "linear(to-l, #9146db, #30ACFB)",
                cursor: "pointer",
              }}
              onClick={() => {
                transition1();
              }}
            >
              Start playing
            </Button>
          </Box>
          <Box ml="auto" mr="auto" my={4} p={4} w="40%" textAlign={"center"}>
            <Box
              bgGradient="linear(to-l, #9146db, #0081d4)"
              borderRadius={10}
              fontSize={"2xl"}
              p={5}
              mb={8}
            >
              <Text fontWeight={"bold"}>
                WIN CASH PRIZES EVERY WEDNESDAY AT 9PM ET
              </Text>
              <Text>First place wins $10!</Text>
            </Box>

            <CountdownTimer />
          </Box>

          <SlideFade in={true} delay={0.4} id="about-text">
            <Fade>
              <Text
                id="about-h2"
                fontSize="3xl"
                bgGradient="linear(to-l, #7928CA, #30ACFB)"
                bgClip="text"
              >
                ABOUT <i className="ri-information-line" />
              </Text>
              <div className="about-w-btn">
                <Box id="about-p" fontSize="2xl">
                  Rapid visual categorization involves seeing an image for a
                  fraction of a second and immediately determining what was in
                  it. In this game, your goal is to determine which images have
                  animals in them!
                  <br />
                  <br />
                  By playing rapid visual categorization, you're helping us
                  improve artificial intelligence (AI) systems, which try to
                  mimic natural vision using computational techniques.
                  <br />
                  <br />
                  Every time you play the game, you're providing researchers
                  with information about the complexities of the human visual
                  system. You are helping us understand how and why humans are
                  able to process what is seen so quickly!
                  <br />
                  <br />
                  Every month, we give cash prizes to the top players on the{" "}
                  <button id="inline-btn" onClick={seeLeaderboard}>
                    leaderboard!
                  </button>
                  <br />
                  <br />
                  <Button
                    as="p"
                    // className="rtrn-btn"
                    bgGradient="linear(to-l, #9146db, #0081d4)"
                    // bgClip="text"
                    p={3}
                    variant={"unstyled"}
                    size="2xl"
                    mb={5}
                    _hover={{ cursor: "pointer", border: "2px solid white" }}
                    onClick={() => {
                      transition1();
                    }}
                  >
                    PLAY NOW TO WIN CASH PRIZES!
                  </Button>
                </Box>
              </div>
            </Fade>
            <Fade>
              <Text
                id="about-h2"
                fontSize="3xl"
                bgGradient="linear(to-l, #7928CA, #30ACFB)"
                bgClip="text"
              >
                MOTIVATION <i className="ri-lightbulb-line" />
              </Text>
              <Box id="about-p" fontSize="2xl">
                Our visual system is a remarkable mystery of nature. Regardless
                of whether an object is blurry, upside down, or transformed in a
                way we have never seen before, we can recognize it nearly
                instantly. Even the latest and greatest AI systems like OpenAI's
                GPT-4 (ChatGPT's successor that can process both vision and
                language) are no match for human vision, across a variety of
                diverse tasks and metrics.
                <br />
                <br />
                We are investigating the contributions of feedforward and
                feedback mechanisms in visual perception using a
                well-established paradigm called rapid visual categorization.
                Decades of neuroscience, psychology, and neurophysiology
                research has shown that the human brain can process visual
                stimuli and detect objects or animals in as little as 20
                milliseconds. By showing images for very short presentation
                times and eliciting quick behavioral responses, we are able to
                uncover the inner workings of the human visual system.
              </Box>
            </Fade>
            <Fade>
              <Text
                id="about-h2"
                fontSize="3xl"
                bgGradient="linear(to-l, #7928CA, #30ACFB)"
                bgClip="text"
              >
                HOW WILL MY DATA BE USED? <i className="ri-pie-chart-2-line" />
              </Text>
              <Box id="about-p" fontSize="2xl">
                We are using the data from this experiment to train a set of AI
                models that are more aligned with biology than anything the
                field has seen. Beyond creating newer and more computationally
                efficient AI, this data can be used to robustly assess the
                alignment between existing computer vision models and humans.
                <br></br>
                <br></br>
                You are in control of your personal information. You always have
                the right to delete your account, permanently removing your
                email and personally identifiable data from all of our
                databases.
              </Box>
            </Fade>

            {/* <Fade>
              <Box id="refs" fontSize="xl">
                [1] Geirhos, Robert, et al. “Generalisation in Humans and Deep
                Neural Networks.” 32nd Conference on Neural Information
                Processing Systems, 2018,
                https://proceedings.neurips.cc/paper/2018/file/0937fb5864ed06ffb59ae5f9b5ed67a9-Paper.pdf.
                <br />
                [2] Metz, Cade, and Keith Collins. “10 Ways GPT-4 Is Impressive
                but Still Flawed.” The New York Times, 14 Mar. 2023,
                www.nytimes.com/2023/03/14/technology/openai-new-gpt4.html.
                <br />
                [3] Thorpe, Simon, et al. “Speed of Processing in the Human
                Visual System.” Nature, vol. 381, no. 6582, 6 June 1996, pp.
                520-522, https://doi.org/10.1038/381520a0.
                <br />
                [4] Eberhardt, Sven, et al. “How Deep Is the Feature Analysis
                Underlying Rapid Visual Categorization?” Advances in Neural
                Information Processing Systems, 3 June 2016,
                https://papers.nips.cc/paper_files/paper/2016/hash/42e77b63637ab381e8be5f8318cc28a2-Abstract.html.
              </Box>
            </Fade> */}
          </SlideFade>
        </SlideFade>
      </Box>
    </>
  );
};

export default Home;
