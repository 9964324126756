import React from "react";

const Flagged = () => {
  return (
    <form>
      <h1 className="intro-header-text">Thank you for participating.</h1>
      <label>You have finished all tasks.</label>
      <br></br>
      <br></br>
    </form>
  );
};

export default Flagged;
