import React, { useEffect, useState } from "react";
import { useCallback } from "react";

const MultipleChoice = (props) => {
  const [correct, setCorrect] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [slow, setSlow] = useState(false);
  const [listening, setListening] = useState(true);
  const backend = process.env.REACT_APP_IP;
  let endPerformanceTimer = performance.now();
  let answered = false;

  // useEffect(() => {
  //   setTimeout(() => {
  //     startTimer = Date.now();
  //     startPerformanceTimer = performance.now();
  //   }, 1000)
    
  // }, [])


  let f, j;
  if (props.keybind < 0.5) {
    f = props.choices[0];
    j = props.choices[1];
  } else {
    j = props.choices[0];
    f = props.choices[1];
  }

  useEffect(() => {
    if (props.quit) {
      props.hideself(false);
      props.setQuit(false);
    }
  }, [props.quit]);

  const recordResponse = async (ans) => {
    answered = true;
    // if (props.catch) {
    //   if (ans != props.choices[0]) {
    //     // props.flagged(true);
    //   } else {
    //     // TODO set flagged if two wrong
    //     if (props.streak > props.bestStreak) {
    //       props.updateBestStreak(props.streak);
    //     }
    //     props.updateStreak(0);
    //   }
    //   props.updateTrialCount(props.trialCount + 1);
    //   sendData(ans);
    //   // props.hideself(false);
    //   // props.transition1();
    // } else
    if (props.practice) {
      if (props.label === ans) {
        setCorrect(true);
        setListening(false);
        setTimeout(() => {
          setCorrect(false);
          setListening(true);
          // props.transition2();

          props.practiceTransition();
          props.hideself(false);
        }, 1000);
      } else {
        setWrong(true);
        setListening(false);
        setTimeout(() => {
          setWrong(false);
          setListening(true);
          // props.transition2();

          props.practiceTransition();
          props.hideself(false);
        }, 1000);
      }
      props.updatePracticeCount(props.practiceCount + 1);
    } else {
      props.updateTrialCount(props.trialCount + 1);
      if (props.label === ans) {
        props.updateCorr(props.corr + 1);
        let curr = props.streak + 1;
        props.updateStreak(curr);
        if (curr > props.bestStreak) {
          props.updateBestStreak(curr);
        }
      } else {
        if (props.streak > props.bestStreak) {
          props.updateBestStreak(props.streak);
        }
        props.updateStreak(0);
      }
      await sendData(ans);
    }
    return;
  };

  const [renderedTime, setRenderedTime] = useState(null);

  const sendData = async (answered) => {
    let answer = "NA";
    let time;
    if (answered != null) {
      answer = answered;
      // if the user responds before the image has shown, this value will be negative
      time = endPerformanceTimer - props.startTimer - 1000;
      if (answer == props.label) {
        let addPoints;
        props.catch ? (addPoints = 1000) : (addPoints = 1000 - Math.round(time));
        props.setPoints(props.points + addPoints);
      }
    } else {
      time = "NA";
      // props.counter(props.count + 1);
    }
    let time_shown;
    props.catch ? (time_shown = 5000) : (time_shown = 33);
    let image_path = props.stimuliPath.split("/");
    const body = JSON.stringify({
      user: props.user,
      block_id: props.blockID,
      image_path: image_path[3],
      synset_id: props.synsetID,
      img_id: parseInt(props.imgID),
      time_shown: parseInt(time_shown),
      f: f,
      choices_shown: props.choices.toString(),
      choice_made: answer,
      true_label: props.label,
      response_time: parseInt(time),
      order_in_block: props.trialCount,
    });
    await fetch(backend + "addTrial", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: body,
    }).then(() => {
      props.hideself(false);

      // stop loop if user has failed to respond 3 times
      // if (props.count > 2) {
      //     props.flagged(true);
      // } else {
      //     props.transition1();
      // }
      props.transition1();
    });
  };

  const handleChoice = useCallback(
    (e) => {
      let response;
      if (listening) {
        if (e.key === "f") {
          endPerformanceTimer = performance.now();
          props.setSeeVideo(false);
          response = props.keybind < 0.5 ? props.choices[0] : props.choices[1];
          props.setSeeImage(false);
          
          recordResponse(response);
        } else if (e.key === "j") {
          endPerformanceTimer = performance.now();
          props.setSeeVideo(false);
          response = props.keybind >= 0.5 ? props.choices[0] : props.choices[1];
          props.setSeeImage(false);
          
          recordResponse(response);
        }
      }
    },
    [listening]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleChoice, false);

    return () => {
      document.removeEventListener("keydown", handleChoice, false); // to prevent mem leaks
    };
  }, [handleChoice]);

  useEffect(() => {
    let timeoutId;
    if (!props.practice && !props.catch) {
      timeoutId = setTimeout(() => {
        if (!answered) {
          setRenderedTime(Date.now());
        }
      }, 1000);
    } else {
      timeoutId = setTimeout(() => {
        // setListening(true);
        if (!answered) {
          setRenderedTime(Date.now());
        }
      }, 4000);
    }

    return () => clearTimeout(timeoutId);
  }, []);

  // timing out on catch trial stops loop
  useEffect(() => {
    if (renderedTime !== null) {
      if (props.catch) {
        setSlow(true);
        setListening(false);
        // props.flagged(true);
        props.updateTrialCount(props.trialCount + 1);

        setTimeout(() => {
          setSlow(false);
          setListening(true);
          sendData(null);
          // props.hideself(false);
          // props.transition1();
        }, 1000);
      } else if (props.practice) {
        setSlow(true);
        setListening(false);
        props.updatePracticeCount(props.practiceCount + 1);
        setTimeout(() => {
          setSlow(false);
          setListening(true);
          props.hideself(false);
          // props.transition2();
          props.practiceTransition();
        }, 1000);
      } else {
        setSlow(true);
        setListening(false);
        props.updateTrialCount(props.trialCount + 1);
        setTimeout(() => {
          setSlow(false);
          setListening(true);
          sendData(null);
          // props.hideself(false);
          // props.transition1();
        }, 1000);
      }
    }
  }, [renderedTime]);

  if (correct) {
    return (
      <div className={`${props.shown ? "" : "active"} show`}>
        <p id="correct">correct</p>
      </div>
    );
  }

  if (wrong) {
    return (
      <div className={`${props.shown ? "" : "active"} show`}>
        <p id="wrong">wrong</p>
      </div>
    );
  }

  if (slow) {
    return (
      <div className={`${props.shown ? "" : "active"} show`}>
        <p id="wrong">Too slow</p>
      </div>
    );
  }
};

export default MultipleChoice;
