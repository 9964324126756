import React from "react";
import { SlideFade } from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Text,
  HStack,
  Button,
} from "@chakra-ui/react";
import { Fade } from "react-awesome-reveal";
import NavBar from "./navbar";
import "remixicon/fonts/remixicon.css";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import CountdownTimer from "./countdowntimer";

const Returning = (props) => {
  const startTrials = () => {
    props.reminder(true);
    props.setInTrial(true);
    props.seeNav(false);
    props.hideself(false);
    props.transition1(true);
  };

  const seeLeaderboard = () => {
    props.hideself(false);
    props.transition2(true);
  };

  // const seeProfile = () => {
  //   props.hideself(false);
  //   props.transition3(true);
  // };

  // // TODO: This should reset the saved user name and session info

  // const signOut = () => {
  //   window.location.reload();
  // };

  return (
    <>
      <div>
        <Box
          display={"flex"}
          // bg="#30ACFB"
          // bgGradient="linear(to-b,#bde5ff, white)"
        >
          {/* <Box px={"5"} fontSize="6xl" textColor="#30ACFB">
            RVIS
          </Box> */}
          {/* <HStack spacing="10" px="5" mr="auto">
            <Button
              as="a"
              href="#about-text"
              onClick={handleAboutClick}
              variant="unstyled"
              fontSize="3xl"
              fontWeight="normal"
            >
              About
            </Button>
            <Button
              variant="unstyled"
              fontSize="3xl"
              fontWeight="normal"
              onClick={() => {
                seeLeaderboard();
              }}
            >
              Leaderboard
            </Button>
            <Button
              as="a"
              href="mailto:m.bluth@example.com"
              fontSize="3xl"
              fontWeight="normal"
              variant="unstyled"
            >
              Contact Us
            </Button>
          </HStack> */}
          {/* <HStack spacing="10" px="5">
            <Menu>
              <MenuButton display="flex" mx={5} ml="auto">
                <Avatar name={props.user} cursor="pointer" />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={seeProfile}>View Profile</MenuItem>
                <MenuItem onClick={signOut}>Sign Out</MenuItem>
              </MenuList>
            </Menu>
          </HStack> */}
        </Box>

        <div id="about" className={`${props.shown ? "" : "active"} show`}>
          <SlideFade in={true} delay={0.2}>
            {/* <Box id="about-h1" fontSize="7xl">
              Welcome back, {window.sessionStorage.getItem("user")}!
            </Box>
            <div className="rtrn-btn-div">
              <button
                className="returning-btns"
                onClick={() => {
                  startTrials();
                }}
              >
                Start playing
              </button>
            </div> */}
            <Box id="about-h1">
              {/* <Text fontSize={'3xl'}>Welcome to </Text> */}
              <Text
                fontSize={"6xl"}
                fontWeight={"bold"}
                bgGradient="linear(to-l, #7928CA, #30ACFB)"
                bgClip="text"
                textAlign={"center"}
              >
                Rapid Visual Categorization
              </Text>
            </Box>

            <Box display={"flex"}>
              <Button
                // className="signup-btn"
                ml="auto"
                mr="auto"
                mt="4"
                // mb="4"
                color="white"
                variant={"outline"}
                height="6vh"
                fontSize="2xl"
                rightIcon={<ArrowForwardIcon />}
                _hover={{
                  bgGradient: "linear(to-l, #9146db, #30ACFB)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  startTrials();
                }}
              >
                Start playing
              </Button>
            </Box>
            <Box ml="auto" mr="auto" my={4} p={4} w="40%" textAlign={"center"}>
              <Box
                bgGradient="linear(to-l, #9146db, #0081d4)"
                borderRadius={10}
                fontSize={"2xl"}
                p={5}
                mb={8}
              >
                <Text fontWeight={"bold"}>
                  WIN CASH PRIZES EVERY WEDNESDAY AT 9PM ET
                </Text>
                <Text>First place wins $10!</Text>
              </Box>

              <CountdownTimer />
            </Box>
            <SlideFade in={true} delay={0.4} id="about-text">
              <Fade>
                <Text
                  id="about-h2"
                  fontSize="3xl"
                  bgGradient="linear(to-l, #7928CA, #30ACFB)"
                  bgClip="text"
                >
                  ABOUT <i className="ri-information-line" />
                </Text>
                <div className="about-w-btn">
                  <Box id="about-p" fontSize="2xl">
                    Rapid visual categorization involves seeing an image for a
                    fraction of a second and immediately determining what was in
                    it. In this game, your goal is to determine which images
                    have animals in them!
                    <br />
                    <br />
                    By playing rapid visual categorization, you're helping us
                    improve artificial intelligence (AI) systems, which try to
                    mimic natural vision using computational techniques.
                    <br />
                    <br />
                    Every time you play the game, you're providing researchers
                    with information about the complexities of the human visual
                    system. You are helping us understand how and why humans are
                    able to process what is seen so quickly!
                    <br />
                    <br />
                    Every month, we give cash prizes to the top players on the{" "}
                    <button id="inline-btn" onClick={seeLeaderboard}>
                      leaderboard!
                    </button>
                    <br />
                    <br />
                    <Button
                      as="p"
                      // className="rtrn-btn"
                      bgGradient="linear(to-l, #9146db, #0081d4)"
                      // bgClip="text"
                      p={3}
                      variant={"unstyled"}
                      size="2xl"
                      mb={5}
                      _hover={{ cursor: "pointer", border: "2px solid white" }}
                      onClick={() => {
                        startTrials();
                      }}
                    >
                      <strong>PLAY NOW TO WIN CASH PRIZES!</strong>
                    </Button>
                  </Box>
                </div>
              </Fade>
              <Fade>
                <Text
                  id="about-h2"
                  fontSize="3xl"
                  bgGradient="linear(to-l, #7928CA, #30ACFB)"
                  bgClip="text"
                >
                  MOTIVATION <i className="ri-lightbulb-line" />
                </Text>
                <Box id="about-p" fontSize="2xl">
                  Our visual system is a remarkable mystery of nature.
                  Regardless of whether an object is blurry, upside down, or
                  transformed in a way we have never seen before, we can
                  recognize it nearly instantly. Even the latest and greatest AI
                  systems like OpenAI's GPT-4 (ChatGPT's successor that can
                  process both vision and language) are no match for human
                  vision, across a variety of diverse tasks and metrics.
                  <br />
                  <br />
                  We are investigating the contributions of feedforward and
                  feedback mechanisms in visual perception using a
                  well-established paradigm called rapid visual categorization.
                  Decades of neuroscience, psychology, and neurophysiology
                  research has shown that the human brain can process visual
                  stimuli and detect objects or animals in as little as 20
                  milliseconds. By showing images for very short presentation
                  times and eliciting quick behavioral responses, we are able to
                  uncover the inner workings of the human visual system.
                </Box>
              </Fade>
              <Fade>
                <Text
                  id="about-h2"
                  fontSize="3xl"
                  bgGradient="linear(to-l, #7928CA, #30ACFB)"
                  bgClip="text"
                >
                  HOW WILL MY DATA BE USED?{" "}
                  <i className="ri-pie-chart-2-line" />
                </Text>
                <Box id="about-p" fontSize="2xl" mb={"10vh"}>
                  We are using the data from this experiment to train a set of
                  AI models that are more aligned with biology than anything the
                  field has seen. Beyond creating newer and more computationally
                  efficient AI, this data can be used to robustly assess the
                  alignment between existing computer vision models and humans.
                  <br></br>
                  <br></br>
                  You are in control of your personal information. You always
                  have the right to delete your account, permanently removing
                  your email and personally identifiable data from all of our
                  databases.
                </Box>
              </Fade>
            </SlideFade>
          </SlideFade>
        </div>
      </div>
    </>
  );
};

export default Returning;
