import React, { useCallback, useEffect } from "react";
import { useState } from "react";

const Directions = (props) => {
  let f, j;
  if (props.keybind < 0.5) {
    f = props.choices[0];
    j = props.choices[1];
  } else {
    j = props.choices[0];
    f = props.choices[1];
  }

  const transition1 = useCallback(
    (e) => {
      props.hideself(false);
      props.showReminder(true);
      props.setInTrial(true);
      props.transition(true);
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener("keydown", transition1, false);

    return () => {
      document.removeEventListener("keydown", transition1, false); // to prevent mem leaks
    };
  }, [transition1]);

  return (
    <>
      <form>
        <h1 className="intro-header-text">Thank you for participating!</h1>
        <label>You will now do a practice session.</label>
        <br></br>
        <label>
          The practice session will serve as a tutorial for you to familiarize
          yourself with the task.
        </label>
        <br></br>
        <label>
          Once the practice session is complete, the main phase will begin.
        </label>
        <br></br>
        <br></br>
        {/* <label>Press <strong>f</strong> on your keyboard if the image is {f} or <strong>j</strong> if the image is {j}.</label>
            <br></br>
            <br></br> */}
        {/* <input
          type="submit"
          onClick={() => {
            transition1();
          }}
          value="CONTINUE"
          className="accept-btn"
        /> */}
        <label>
          <strong>Press any key to continue</strong>
        </label>
        <br />
        <br />
      </form>
    </>
  );
};

export default Directions;
