import React from "react";

const PlayAgain = ({ hideself, transition }) => {
  const transition1 = () => {
    hideself(false);
    transition();
  };

  return (
    <form>
      <h1 className="intro-header-text">Thank You for Playing!</h1>
      <h1 className="intro-header-text">Play again?</h1>
      <input
        type="submit"
        onClick={() => {
          transition1();
        }}
        value="YES"
        className="accept-btn"
      />
    </form>
  );
};

export default PlayAgain;
