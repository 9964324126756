import React from "react";

const Intro = ({ hideself, transition }) => {
  const transition1 = () => {
    hideself(false);
    transition(true);
  };

  // TODO : hide sitekey - currently visible via dev tools
  // TODO : set up captcha to work with backend
  return (
    <>
      <form>
        <h1 className="intro-header-text">Introduction</h1>
        <label>
          By clicking accept, you are agreeing to participate in a cognitive
          experiment by the Serre Lab and have your results recorded.
        </label>
        <br></br>
        <label>
          List any other warnings or permissions that must be asked. Collect any
          user data we want.
        </label>
        <br></br>
        <label>
          Once you click the button, a short diagnostic will be run.
        </label>
        <br></br>
        <br></br>
        <input
          type="submit"
          onClick={() => {
            transition1();
          }}
          value="ACCEPT"
          className="accept-btn"
        />
      </form>
    </>
  );
};

export default Intro;
