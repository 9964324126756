import React from "react";

const Continue = (props) => {
  // TODO: can make this the same - use props to call transition conditionally ?

  const transition1 = () => {
    props.hideself(false);
    props.updateTotal(0);
    props.updateCorr(0);
    props.setPoints(0);
    props.updateStreak(0);
    props.updateBestStreak(0);

    props.transition();
  };

  const transition2 = () => {
    props.hideself(false);
    props.updateTotal(0);
    props.updateCorr(0);
    props.setPoints(0);
    props.updateStreak(0);
    props.updateBestStreak(0);
    props.toggleReminder(false);
    props.seeNav(true);
    props.transition2(true);
    props.setInTrial(false);
  };

  return (
    <form className="continue">
      <h1 className="intro-header-text">Block Complete!</h1>
      <label>Thank you for playing!</label>
      <br></br>
      <h2>
        {props.corr}/{props.total} trials correct
      </h2>
      <h2>Best answer streak: {props.bestStreak} &#128293;</h2>
      <h2>+{props.points} points</h2>
      {/* TODO: should be users new score with the addition of points */}
      <br></br>
      <label>Continue?</label>
      <br></br>
      <div className="continue-btn-wrapper">
        <input
          type="submit"
          onClick={() => {
            transition1();
          }}
          value="YES"
          className="continue-btn-yes"
        />
        <input
          type="submit"
          onClick={() => {
            transition2();
          }}
          value="NO"
          className="continue-btn-no"
        />
      </div>
    </form>
  );
};

export default Continue;
