import React from "react";

const Failed = ({ hideself, transition }) => {
  const transition1 = () => {
    hideself(false);
    transition();
  };

  return (
    <form>
      <h1 className="intro-header-text">
        Sorry, there has been an error fetching the content!
      </h1>
      <h1 className="intro-header-text">Try again?</h1>
      <input
        type="submit"
        onClick={() => {
          transition1();
        }}
        value="YES"
        className="accept-btn"
      />
    </form>
  );
};

export default Failed;
