import React, { useState, useEffect } from "react";
import {
  Avatar,
  Text,
  Card,
  Fade,
  Flex,
  SlideFade,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";
import { Gradient } from "typescript-color-gradient";
import PrizeBadge from "./prizebadge";
import CountdownTimer from "./countdowntimer";

const Leaderboard = (props) => {
  const [topUsers, setTopUsers] = useState([]);
  const backend = process.env.REACT_APP_IP;
  const gradientArray = new Gradient()
    .setGradient("#30ACFB", "#7928CA")
    .getColors();

  useEffect(() => {
    fetch(backend + "topUsers", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res !== "no users found" && res) {
          if (res.topUsers) {
            setTopUsers(res.topUsers);
          }
        }
      })
      .catch(console.error);
  }, [setTopUsers]);

  const transition = () => {
    props.hideself(false);
    if (window.sessionStorage.getItem("user")) {
      props.transition(true);
    } else {
      props.home(true);
    }
  };

  return (
    <>
      <Box className={`${props.shown ? "" : "active"} show`}>
        <Box
          // width={"55%"}
          w={{ base: "100%", md: "70%", lg: "50%" }}
          // pt='1'
          // pl={'50%'}
          mr="auto"
          ml="auto"
          mt={{ base: "5vh" }}
          // boxShadow= 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px'

          // boxShadow= 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px'
          // boxShadow='rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
        >
          <SlideFade in={true} delay={0.2}>
            <Text
              id="leaderboard-h1"
              fontSize={{ base: "3xl", md: "3xl", lg: "5xl" }}
              fontWeight={"bold"}
              bgGradient="linear(to-l, #7928CA, #30ACFB)"
              bgClip="text"
            >
              Leaderboard (Top 10)
            </Text>
            <CountdownTimer />
          </SlideFade>
          <Fade in={true}>
            {topUsers.length > 0 ? (
              topUsers.map((user, key) => (
                <SlideFade in={true} delay={0.05 * key} key={key}>
                  <Flex
                    alignItems="center"
                    className="cards"
                    align={"center"}
                    my={3}
                    // pb={3}
                    p={1}
                  >
                    <Box
                      w="5%"
                      // fontWeight="bold"
                      // fontSize="xl"
                      // align={"center"}
                      mr={{ base: "6", lg: "8" }}
                      // mr={8}
                      my="2"
                      // ml="0"
                      // m={8}
                      // color="gray.400"
                      // opacity={key < 3? '100%' : '50%'}

                      // boxShadow= 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
                    >
                      {key === 0 ? (
                        <PrizeBadge amount={user.points} prizeKey={key} />
                      ) : (
                        <Box
                          width={{ base: "8", lg: "12" }}
                          height={{ base: "8", lg: "12" }}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="center"
                          textAlign="center"
                          fontWeight="bold"
                          fontSize={{ base: "md", md: "lg", lg: "xl" }}
                          color="gray.400"
                        >
                          <Text> {key + 1}</Text>
                        </Box>
                      )}
                    </Box>
                    <Card
                      w="100%"
                      paddingX={{ base: "3", md: "5" }}
                      rounded="full"
                      p={{ base: "2", lg: "5" }}
                      // marginBottom="5"
                      // display="flex"
                      align={"center"}
                      // boxShadow='none'
                      boxShadow={key < 3 ? "outline" : "none"}
                      // boxShadow= 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      // bgColor={gradientArray[topUsers.indexOf(user)] + "30"}
                      bgColor="#1a202c"
                      mr={{ base: "2" }}
                    >
                      <Flex flexDirection="row" alignItems="center" p="2">
                        {/* <Avatar
                        
                        marginRight={{ base: "2", md: "4" }}
                        name={user.username}
                        size="md"
                        
                        // bgColor={gradientArray[topUsers.indexOf(user)]}
                      /> */}
                        <Text
                          margin="0"
                          fontSize={{ base: "md", md: "lg", lg: "xl" }}
                          // fontWeight={key < 3? 'bold': 'none'}
                          fontWeight="bold"
                          color={key < 3 ? "white" : "gray.400"}
                        >
                          {user.username.length < 20
                            ? user.username
                            : user.username.slice(0, 20) + "..."}
                        </Text>
                      </Flex>
                      <Text
                        color={key < 3 ? "#93bdf5" : "gray"}
                        fontWeight="bold"
                        margin="0"
                        fontSize={{ base: "md", md: "lg", lg: "xl" }}
                      >
                        {user.points.toLocaleString()}
                      </Text>
                    </Card>
                  </Flex>
                </SlideFade>
              ))
            ) : (
              <p id="user-alert">No users found</p>
            )}
          </Fade>
          {/* <button id="back-btn" onClick={transition}>
          Back home
        </button> */}
        </Box>
      </Box>
    </>
  );
};

export default Leaderboard;
